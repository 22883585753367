import React, { Component } from 'react';

import logo from '../images/logo.jpg'


const Footer = ({successToggleModal})=>{


   return (
      <section id='footer'>

         <div className="container">
            <div class="row">
               <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className='footer_logo'>
                     <a href='#'> <img src={logo} /></a>
                  </div>
                  <ul className="about_us">
                     <li>Discover new ways to market
                        your business digitally.
                        <h4>Faster | Better | Easier</h4>
                     </li>
                  </ul>

               </div>
              
              
               <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                  <div className='ftr-cont'>  <h3>Contact Us</h3></div>
                  <ul className="link_menu">
                     <li><a href="#visitors">About Us</a></li>
                     <li><a href="#helpyou">Services</a></li>
                     <li><a href="javascript:void(0)" onClick={successToggleModal}>Contact Us</a></li>
                  </ul>
               </div>
               <div className="col-lg-12 ">
                  <form className="bottom_form">
                     <div className='news_left'>
                        <h3>Newsletter</h3>
                     </div>
                     <div className='news_right'>
                        <input className="enter" placeholder="Enter your email" type="text" name="Enter your email" />
                        <button className="sub_btn">subscribe</button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
         <div className="copyright">
            <div className="container">
               <div className="row">
                  <div className="col-md-10 offset-md-1">
                     <div className='allright'>
                        <p>© 2023 All Rights Reserved.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </section>

   );
}

export default Footer;