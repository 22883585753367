import React, { Component, useEffect, useRef, useState } from "react";
import { Row, Col, Container, Button, ModalHeader, ModalFooter, Modal, ModalBody } from "reactstrap";
import emailjs from '@emailjs/browser';
import $ from "jquery";
import Header from '../header'
import Footer from '../footer'
import pct from '../../images/pct.png'
import sco from '../../images/sco.jpg'
import one from '../../images/one.png'
import two from '../../images/two.png'
import three from '../../images/three.png'
import four from '../../images/four.png'
import team from '../../images/team.png'
import mngment from '../../images/mngment.png'
import ppcimg from '../../images/ppcimg.jpg'
import man1 from '../../images/man1.jpg'
import man2 from '../../images/man2.jpg'
import man3 from '../../images/man3.jpg'




function Home() {



    const [successModal, setsuccessModal] = useState(false);
    const successToggleModal = () => setsuccessModal(!successModal);

    const [emailsentModal, setEmailsentModal] = useState(false);
    const emailsentToggleModal = () => setEmailsentModal(!emailsentModal);

    const [fillallfieldmodal, setFillallfieldModal] = useState(false);
    const fillallfieldtoggleModal = () => setFillallfieldModal(!fillallfieldmodal);

    const form = useRef();

    const name = useRef();
    const email = useRef();
    const mobile = useRef();
    const message = useRef();
    
    

    const sendEmail = (e) => {
        e.preventDefault();
        if (name.current.value == "" || email.current.value == "" || mobile.current.value == "" || message.current.value == "" ) {
            fillallfieldtoggleModal();
           
            return false;
        }
        emailjs.sendForm('service_v3tkpsi', 'template_02k5ld6', form.current, '5LK3ltSxQXie3wS_g')
            .then((result) => {
                console.log(result.text);
                emailsentToggleModal()
                setsuccessModal(false);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div>
            <Header successToggleModal={successToggleModal} />
            <div className='mainbg'>

                <div className='bnrbutn'>
                    <a href='#' onClick={successToggleModal}>Learn More</a>
                </div>
                {/* <section id='bnr'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                            <div className='bnrimg'>
                                    <img src={pct}></img>
                                </div>
                                <div className='bnr-cont'>
                                    <h3>Google Ads PPC Agency</h3>
                                    <p>With 85% of our clients' ads appearing at the top of Google, we help you get more valuable visitors
                                       <br/> to your website and grow your business using Google Ads.</p>
                                    <div className='bnrbutn'>
                                        <a href='#' onClick={successToggleModal}>About More</a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section> */}
            </div>
            <section id='visitors'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='visitorsimg'>
                                <img src={sco}></img>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='visitors-cont'>
                                <h3 >For more valuable visitors to your website, Google Ads is a no-brainer!</h3>
                                <p>You already know that Google is the first place that your customers search for information on
                                    products. Therefore, You probably also know that getting visitors to your website with Google Ads is
                                    a great opportunity to grow your business.
                                </p>
                                <p>The thing is, so does your competition. That’s why it’s not always easy to get the results you want.
                                </p>
                                <p>If you want to make the most of the opportunity, send more visitors to your website, and really grow
                                    your business it might be time to work with a team of Google certified PPC experts who have a track
                                    record of delivering results.
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id='wemean'>

                <div className='container'>
                    <div className='meanhding'>
                        <h3>Here’s what we mean.</h3>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <a className='meanbx-wrp' href='#'>
                                <div className='mean-bx'>
                                    <p>85% of our ads
                                        appear at the top
                                        of the page</p>
                                </div>
                            </a>

                        </div>
                        <div className='col-lg-4'>
                            <a className='meanbx-wrp' href='#'>
                                <div className='mean-bx'>
                                    <p>73% of our ads
                                        outrank
                                        competitors ads
                                        on shared
                                        keywords
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className='col-lg-4'>
                            <a className='meanbx-wrp' href='#'>
                                <div className='mean-bx'>
                                    <p>8/10 of our
                                        clients see at
                                        least 40%
                                        reduction in
                                        wasted budget</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='wemean-r'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <a className='meanbx-wrp' href='#'>
                                    <div className='mean-bx'>
                                        <p>7/10 of our
                                            clients see at
                                            least 150%
                                            increase in leads</p>
                                    </div>
                                </a>
                            </div>
                            <div className='col-lg-6'>
                                <a className='meanbx-wrp' href='#'>
                                    <div className='mean-bx'>
                                        <p>100% of our
                                            clients see at
                                            least a 30%
                                            increase in
                                            high-quality
                                            traffic</p>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section id='helpyou'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='hy-hding'>
                                <h3>Want more visitors from Google?</h3>
                            </div>

                        </div>


                        <div className='col-lg-9'>
                            <div className='hy-cont-wrp'>
                                <div className='hy-cont'>
                                    <img src={one} />
                                    <p><strong>Research.</strong> Your business goals are the focus for a <strong>winning strategy</strong> that will drive valuable
                                        traffic to your website</p>
                                </div>
                                <div className='hy-cont'>
                                    <img src={two} />
                                    <p><strong>Launch.</strong> Your strategy is the blueprint for a <strong> custom-built ad account</strong> designed to reach your
                                        goals</p>
                                </div>
                                <div className='hy-cont'>
                                    <img src={three} />
                                    <p><strong>Compete</strong> . In such a competitive environment its vital that we use data to <strong> continually optimise</strong>  your Google Ads are to acquire more and more valuable traffic</p>
                                </div>
                                <div className='hy-cont'>
                                    <img src={four} />
                                    <p><strong>Grow</strong> Once we’ve taped into a rich source of traffic, your advertising is <strong> scaled up</strong>  to
                                        supercharge your business growth</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='wemean'>

                <div className='container'>
                    <div className='meanhding'>
                        <h3>Case Studies</h3>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <a className='meanbx-wrp' href='javascript:void(0)'>
                                <div className='mean-bx-c'>
                                    <p>A top five Irish bank had grown
                                        frustrated with PPC and
                                        questioned its value. They
                                        became advocates when we
                                        rebuilt their account and they
                                        achieved their best results
                                        ever. <span><a href='#'>Download the case study</a></span>
                                    </p>
                                </div>
                            </a>

                        </div>
                        <div className='col-lg-4'>
                            <a className='meanbx-wrp' href='javascript:void(0)'>
                                <div className='mean-bx-c'>
                                    <p>Foster Care Ireland needed
                                        assistance recruiting families
                                        to support their worthy cause.
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className='col-lg-4'>
                            <a className='meanbx-wrp' href='javascript:void(0)'>
                                <div className='mean-bx-c'>
                                    <p>Tranzaura is an Irish success
                                        story. Tranzaura offers a world
                                        class Fleet Technology SAAS
                                        solution to some of the largest
                                        fleets in Europe. We’re helping
                                        them achieve their goals.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='case-list-wrp'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <a className='meanbx-wrp' href='javascript:void(0)'>
                                    <div className='mean-bx-a'>
                                        <ul className='case-list'>
                                            <li>
                                                <p> 52% More Qualified Visitors</p>
                                            </li>
                                            <li>
                                                <p>155% Increase in Booked Appointments</p>
                                            </li>
                                            <li>
                                                <p>36% Reduction in Cost per Conversion</p>
                                            </li>
                                        </ul>
                                    </div>
                                </a>

                            </div>
                            <div className='col-lg-4'>
                                <a className='meanbx-wrp' href='javascript:void(0)'>
                                    <div className='mean-bx-a'>
                                        <ul className='case-list'>
                                            <li>
                                                <p> 110% Increase in Visitors</p>
                                            </li>
                                            <li>
                                                <p>800% Increase in Leads</p>
                                            </li>
                                            <li>
                                                <p>90% Reduction in Cost per Conversion</p>
                                            </li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                            <div className='col-lg-4'>
                                <a className='meanbx-wrp' href='#javascript:void(0)'>
                                    <div className='mean-bx-a'>
                                        <ul className='case-list'>
                                            <li>
                                                <p>68% Increase in Visitors</p>
                                            </li>
                                            <li>
                                                <p>340% Increase in Leads</p>
                                            </li>
                                            <li>
                                                <p>44% Reduction in Cost per Conversion</p>
                                            </li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section id='wemean'>

                <div className='container'>
                    <div className='testimonial'>
                        <h3>Testimonials</h3>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='testimonial-bx'>
                                <div className='testimonial-cont'>
                                    <div className='testi-img'>
                                        <img src={man1} />
                                    </div>
                                    <div className='testi-cont'>
                                        <h2><strong>David Taylor,</strong><br /><span>CEO, Foster Care Ireland</span></h2>
                                    </div>
                                </div>
                                <div className='line'></div>
                                <p>As our media experts, Imperic is our trusted partner to enhance the business value of our media
                                    spend. Their professional approach is client focussed and consistent at the levels of operations,
                                    account management and strategy review.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='testimonial-bx'>
                                <div className='testimonial-cont'>
                                    <div className='testi-img'>
                                        <img src={man2} />
                                    </div>
                                    <div className='testi-cont'>
                                        <h2><strong>Avril Mc Ardle,</strong><br /><span>Head of Marketing, Glanbia</span></h2>
                                    </div>
                                </div>
                                <div className='line'></div>
                                <p>These guys are total experts and we consider them very much an extended part of our team. They
                                    have added so much value and are an incredible cost effective resource for us to deliver new
                                    customers for the service.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='testimonial-bx'>
                                <div className='testimonial-cont'>
                                    <div className='testi-img'>
                                        <img src={man3} />
                                    </div>
                                    <div className='testi-cont'>
                                        <h2><strong>Ciara Roca –</strong><br /><span>Marketing Director, Sage</span></h2>
                                    </div>
                                </div>
                                <div className='line'></div>
                                <p>Brilliant agency, always go the extra mile, nothing is an issue, great creative ideas
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section id='choose'>

                <div className='container'>
                    <div className='choose-us'>
                        <h3>Why choose us</h3>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <ul className='choose-cont'>
                                <li><p>Your business is in great hands with our dedicated account managers who offer their
                                    expertise and guidance</p></li>
                                <li><p>Live reports will keep you in the loop on performance at all times. Download a sample report
                                    to see the insights you’ll receive
                                </p></li>
                                <li><p>Maximise your budget’s potential and get more valuable traffic
                                </p></li>
                                <li><p>We’re a Google certified agency. That means Google trusts us to manage your campaigns
                                    because they know we’ll always apply best practices and always focus on the best outcome
                                    for you.
                                </p></li>
                                <li><p> You get the best value and results for your business through our use of automation tools
                                </p></li>
                                <li><p>We’re a business too - we get it - spending money on advertising is an investment in business
                                    growth. The value of money and how it is earned is not wasted on us.
                                </p></li>
                            </ul>
                        </div>

                    </div>

                </div>
            </section>


            <section id='secret-wrp'>

                <div className='container'>
                    <div className='secret'>
                        <h3>No secret sauce or unicorn dust, it’s just consistent
                            day-to-day tasks that brings you more visitors
                        </h3>
                    </div>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <ul className='secret-cont'>
                                <li><p>Budget management</p></li>
                                <li><p>Bid management</p></li>
                                <li><p>Negative keyword removal</p></li>
                                <li><p>New keyword research</p></li>
                                <li><p>Conversion point setup</p></li>
                                <li><p>Quality score optimisation</p></li>
                                <li><p>Ad testing</p></li>
                                <li><p>Competitor analysis</p></li>
                                <li><p>Consulting with Google</p></li>
                                <li><p> Using Google's machine learning and automation</p></li>


                            </ul>
                        </div>
                        <div className='col-lg-7'>
                            <div className='secret-img'>
                                <img src={mngment} />
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section id='ppc-wrp'>

                <div className='container'>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='ppc-img'>
                                <img src={ppcimg} />
                            </div>
                        </div>
                        <div className='col-lg-6'>

                            <div className='secret pcc'>
                                <h3>You can’t be great at PPC if you don’t enjoy it. </h3>
                                <p>Our passion for PPC comes from our people, who love what they do. We find enjoyment in reaching
                                    the right person at the right time, increasing CTRs and reducing CPCs, finding valuable keywords and
                                    nailing bad ones, and creating easy to read reports that show you the next steps towards growth.</p>
                            </div>

                        </div>


                    </div>

                </div>
            </section>
            <section id='likework-wrp'>

                <div className='container'>
                    <div className='likework'>
                        <h3>Like to work with us?</h3>
                        <p>The key to reaching business goals with our clients is a good working relationship. A good working
                            relationship is based on communication and meeting reasonable expectations. So we like to make
                            sure we can meet yours before we start working together. Check out the list below to see if we are a
                            good fit...
                        </p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='like-cont-hding'>
                                <h2>We may be a good fit if...</h2>
                                <ul className='like-list'>
                                    <li><p>You’re looking for long-term, sustainable growth</p></li>
                                    <li><p>You’re willing to invest in PPC over several months or years</p></li>
                                    <li><p>You have invested or plan to invest in your website and content and you’re ready to get traffic</p></li>
                                    <li><p>You have a dedicated person who looks after digital marketing in your business</p></li>
                                    <li><p>You want a partner who can provide a scalable set of services to grow with you along the way</p></li>
                                    <li><p> You can work with a logical methodical process driven agency who don’t take shortcuts</p></li>
                                </ul>
                                <p>You’ve made it this far - if you still think we’re a good fit and you have an appetite for growth, why
                                    not get in touch to discuss your business requirements.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section id='cta-wrp'>

                <div className='container'>
                    <div className='cta'>
                        <h3>Start Getting More Visitors.</h3>
                        <button onClick={successToggleModal}>Get Started</button>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='cta-cont'>
                                <p>As Google is the first place that your customers go for
                                    information when buying, Google Ads is your first choice for
                                    growing your business.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='cta-cont'>
                                <p>That’s why it’s important to use a Google certified team of
                                    PPC experts with a track record of delivering the results.


                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='cta-cont'>
                                <p>Guess what… We ARE that team of Google Certified PPC
                                    experts! And we have designed a PPC growth plan that will
                                    take your business to the next level.


                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section id='alt-wrp'>

                <div className='container'>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='cta'>
                                <h3>How we do it</h3>
                                <p>It's vital to understand your business goals and develop a strategy to achieve them. We implement a
                                    3 phase plan to get a consistent flow of valuable traffic to your site.
                                </p>
                            </div>
                            <div className='alt-cont-wrp'>
                                <ul className='alt-cont-list'>
                                    <li>
                                        <h2>Launch</h2>
                                        <p>The best place to start is research and analysis. Understanding the target keywords, search
                                            volumes, and costs allows us to develop a strategy implement a new PPC strategy designed
                                            for your business that sets you on a trajectory towards your goals. </p>
                                    </li>
                                    <li> <h2>Compete Phase</h2>
                                        <p>Every search on Google triggers an ad auction and for the advertisers it's all about beating
                                            the competition. Our tried and tested tactics keep your goals in sight. </p></li>
                                    <li>

                                        <h2>Grow Phase</h2>
                                        <p>Learn what levers to pull to scale up your ads and grow with predictable results.</p>
                                    </li>
                                </ul>


                            </div>
                        </div>
                        <div className='col-lg-6'>


                            <div className='cta'>
                                <h3>How we do it</h3>
                            </div>
                            <div className='alt-cont-wrp'>
                                <div className='alt-cont'>
                                    <p><strong>Increased traffic</strong> - Google Ads is the best way to increase traffic on website. The best thing about
                                        Google Ads is that it can target demographic and keywords to bring in relevant traffic to your
                                        website.
                                    </p>
                                </div>
                                <div className='alt-cont'>
                                    <p><strong>Better ROI</strong> - As a seasoned Google Ads company, our Google Ads management service offer trackable
                                        and ROI driven strategy
                                    </p>
                                </div>
                                <div className='alt-cont'>
                                    <p><strong>Increased leads</strong> - Google Ad is the best way to increase leads and sales. The best thing about Google
                                        Ads is that it can target demographic and keywords to ready to buy prospect.
                                    </p>
                                </div>
                                <div className='alt-cont'>
                                    <p><strong>Brand visibility</strong> - With Google Ads, your website can be top of the search results, it gives more
                                        exposure to your business and branding by targeting your business and brand keywords
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </section>
            <Footer successToggleModal={successToggleModal} />
            <Modal isOpen={successModal} toggle={successToggleModal} className="connect-box" centered={true}>
                <ModalHeader toggle={successToggleModal}><span className="ml-1 roititle">Contact Us</span></ModalHeader>
                <ModalBody>
                    <form ref={form} onSubmit={sendEmail}>
                        <div>
                            <ul className="form-list">
                                <li><input placeholder="Name" name="user_name" ref={name} /></li>
                                <li><input placeholder="Email" name="user_email" ref={email} /></li>
                                <li><input placeholder="Mobile No" name="phone_number" ref={mobile} /></li>
                                <li><textarea placeholder="Write Message" name="message" ref={message}></textarea></li>
                                <li><div className="btn-submit"><button type="submit" value="Send">Submit</button></div></li>
                            </ul>
                        </div>
                    </form>
                </ModalBody>

            </Modal>


            <Modal isOpen={emailsentModal} toggle={emailsentToggleModal} className="connect-box" centered={true}>
                <ModalHeader toggle={emailsentToggleModal}><span className="ml-1 roititle">Successfull</span></ModalHeader>
                <ModalBody>
                    <div className="success-mail">
                        <p>Your form has been Submit Successfully!</p>
                    </div>
                </ModalBody>

            </Modal>

            <Modal isOpen={fillallfieldmodal} toggle={fillallfieldtoggleModal} className="connect-box" centered={true}>
                <ModalHeader toggle={fillallfieldtoggleModal}><span className="ml-1 roititle font-weight-bold">Error</span></ModalHeader>
                <ModalBody>
                    <div className="success-mail">
                        <p>Please fill all field</p>
                    </div>
                </ModalBody>

            </Modal>
        </div>
    );
}

export default Home; 